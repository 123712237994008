<template>
  <section v-if="loading.status">
    <div class="loading-alert">
      <span><b-spinner small  variant="primary"/>
        {{
          loading.type === 'get' ? 'Buscando' :
          loading.type === 'delete' ? 'Excluindo' :
          loading.type === 'create' ? 'Criando' : ''
        }} campos do convênio. Por favor, aguarde.</span>
    </div>
  </section>
  <section v-else-if="Object.keys(requiredFields).length" class="required-fields">
    <div class="required-fields__title-container">
      <span class="required-fields-title">Obrigatoriedade de campos <InfoIcon id="info-badge" class="cursor-pointer" /></span>
      <b-tooltip target="info-badge" triggers="hover" placement="top" custom-class="custom-fields-tooltip">
        Selecione os campos que deverão ser obrigatórios para gerar as guias relacionadas a este convênio. Os campos não selecionados serão opcionais.
      </b-tooltip>
      <span class="delete-settings" @click="deleteSettings"><TrashBin /> Excluir configuração</span>
    </div>
    <div v-for="category in Object.keys(requiredFields)" :key="category" class="required-fields__category">
      <span class="required-fields__category__title">{{ parseCategory(category) }}</span>
      <div class="required-fields__category__items">
        <div v-for="field in requiredFields[category]" :key="field.id" class="item">
          <input class="form-control" id="category-fieldname" name="category-fieldname" type="checkbox" v-model="field.pivot.active">
          <span>{{ field.label }}</span>
        </div>
      </div>
    </div>
  </section>
  <section v-else class="no-required-fields">
    <NoSettings />
    <span>Não há uma configuração específica para este convênio.</span>
    <button class="btn btn-primary" @click="createSettings">Criar configuração</button>
  </section>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
  name: 'HealthPlanRequiredTissFields',
  props: {
    healthPlanId: {
      type: String,
      required: true
    }
  },
  components: {
    InfoIcon: () => import('@/assets/icons/info-icon.svg'),
    TrashBin: () => import('@/assets/icons/trash_bin.svg'),
    NoSettings: () => import('@/assets/images/no-hp-required-fields.svg')
  },
  data() {
    return {
      healthPlanFields: [],
      loading: {
        status: false,
        type: ''
      }
    }
  },
  computed: {
    requiredFields(){
      if(!this.healthPlanFields.length) return {}
      return this.healthPlanFields.reduce((acc, field) => {
        if(!acc[field.category]){
          acc[field.category] = []
        }
        acc[field.category].push(field)
        return acc
      }, {})
    }
  },
  mounted() {
    this.getHealthPlanRequiredFields()
  },
  methods: {
    ...mapActions('tiss', ['setHealthPlansFields']),
    getHealthPlanRequiredFields(){
      this.healthPlanFields = []
      this.setHealthPlansFields([])
      if(this.loading.type !== 'create'){
        this.loading = {status: true, type: 'get'}
      }
      this.api.getHealthPlanRequiredFields(this.healthPlanId).then(({data}) => {
        if(data.length){
          this.healthPlanFields = data
        }
      }).catch(error => {
        this.$toast.error('Erro ao buscar campos obrigatórios do plano de saúde: ' + error.message)
      }).finally(() => {
        this.loading = {status: false, type: ''}
      })
    },
    parseCategory(category){
      if(category === 'consultation_guide'){
        return 'Guia de Consulta'
      }
      if(category === 'fee_guide'){
        return 'Guia Honorários'
      }
      if(category === 'multiple_guides'){
        return 'Múltiplas guias'
      }
      if(category === 'procedures'){
        return 'Procedimentos'
      }
      if(category === 'spsadt_guide'){
        return 'Guia SP/SADT'
      }
    },
    deleteSettings(){
      this.$swal.fire({
        title: 'Excluir configuração',
        html: `<div class="body-alert">
          Esta ação não poderá ser desfeita. Deseja realmente excluir a configuração?
          </div>`,
        showCancelButton: true,
        confirmButtonColor: '#305BF2',
        confirmButtonText: 'Confirmar',
        cancelButtonColor: 'transparent',
        cancelButtonText: '<span style="color: red;">Cancelar</span>',
        cancelButtonHoverColor: '#ff0000',
        showCloseButton: true,
        reverseButtons: true,
        customClass: {
          popup: "custom-swal-clear-passwords",
        },
      }).then(async res => {
        if (res.isConfirmed) {
          this.setHealthPlansFields([])
          this.loading = {status: true, type: 'delete'}
          this.api.deleteHealthPlanRequiredFields(this.healthPlanId).then(() => {
            this.$toast.success('Campos obrigatórios do plano de saúde excluídos com sucesso')
            this.healthPlanFields = []
          }).catch(error => {
            this.$toast.error('Erro ao excluir campos obrigatórios do plano de saúde: ' + error.message)
          }).finally(() => {
            this.loading = {status: false, type: ''}
          })
        }
      })
    },
    createSettings(){
      this.setHealthPlansFields([])
      this.loading = {status: true, type: 'create'}
      this.api.createHealthPlanRequiredFields(this.healthPlanId).then(() => {
        this.getHealthPlanRequiredFields()
      }).catch(error => {
        this.$toast.error('Erro ao criar campos obrigatórios do plano de saúde: ' + error.message)
      })
    }
  },
  watch: {
    healthPlanFields: {
      async handler(newVal) {
        newVal.map(field => {
          if(field.pivot.active){
            field.pivot.active = 1
          } else {
            field.pivot.active = 0
          }
        })
        await this.setHealthPlansFields(newVal);
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.required-fields {
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 16px;

  &__title-container {
    display: flex;
    justify-content: space-between;

    .required-fields-title {
      font-size: 16px;
      font-weight: 600;
      color: #142666;
    }

    .delete-settings {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #305BF2;
      cursor: pointer;

      svg {
        width: 16px;
        height: 20px;
        fill: #305BF2;
      }
    }
  }

  &__category {
    &__title {
      font-weight: 600;
    }

    padding: 8px 16px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 8px;

      .item {
        display: flex;
        align-items: center;
        gap: 8px;
        min-width: 208px;

        input {
          width: 16px;
          height: 16px;
        }

        span {
          display: flex;
          height: 24px;
          flex-direction: column;
          justify-content: center;
          font-weight: 400;
        }
      }
    }
  }
}
.no-required-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  min-height: calc(100vh - 420px);
  margin: 70px;

  span {
    font-size: 16px;
    font-weight: 400;
    color: #142666;
  }

  button {
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #305BF2;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
}

.loading-alert {
  min-height: calc(100vh - 420px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #142666;
  margin: 24px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>